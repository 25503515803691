import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
import ArticlePreviewLayout from "../../components/articlePreviewLayout";
import useSWR from "swr";
import {graphqlFetcher} from "../../modules/swrFetchers";
import LoadingIndicator from "../../components/loadingIndicator";
import {DateTime} from "luxon";

/**
 * EventHighlights page
 * @returns {JSX.Element}
 * @private
 */
const EventHighlights = () => {
    const {t} = useTranslation();
    const {language, languages, originalPath} = useI18next();
    const type = "event-highlights"

    const [newsList, setNewsList] = useState([]);
    // const [isError, setIsError] = useState(false);
    const pageGraphQL = `
    query GetPostsEdges($categoryName: String!) {
      posts(where: {categoryName: $categoryName}) {
        edges {
          node {
            title
            date
            id
          }
        }
      }
    }
    `
    const pageGraphQLVar = {
        categoryName: `event-highlights-${language}`,
    }
    const { data, error } = useSWR([pageGraphQL, pageGraphQLVar], graphqlFetcher)

    const breadcrumbs = [
        {
            title: t('media'),
            url: "/media"
        },
        {
            title: t('eventHighlights')
        }
    ]


    useEffect(() => {
        if (data?.posts?.edges[0]?.node?.id) {
            const tmpNewsList = data.posts.edges.map(e => {
                return e.node;
            })
            setNewsList(x => [...tmpNewsList]);
            // setIsError(false);
        }
        else {
            //     if (data) {
            //         setIsError(true)
            //     }
            setNewsList(x => []); // initialize an empty array
        }

    }, [data])

    return (
        <Layout>
            <SEO title={t('eventHighlights')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('eventHighlights')}
                mode={"blog"}
            >
                <div className="row">
                    {/*<div className="col-lg-8 entries">*/}
                    <div className="col entries">
                        {!data && !error && (
                            <>
                                <LoadingIndicator />
                            </>
                        )}

                        {data && Array.isArray(newsList) && newsList.length === 0 && (
                            <>
                                {t('noEventHighlightsAvail')}
                            </>
                        )}

                        {newsList.map((e,i) => {
                            return (
                                <ArticlePreviewLayout
                                    key={e.id}
                                    title={e.title}
                                    date={DateTime.fromISO(e.date).toLocaleString()}
                                    dateISO={DateTime.fromISO(e.date).toISO()}
                                    type={type}
                                    articleId={e.id}
                                />
                            )
                        })}

                    </div>
                </div>
            </InnerPage>
        </Layout>
    );
};

export default EventHighlights;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;